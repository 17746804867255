<template>
  <div>
    <h1 class="text-blue">{{ getService.name }}</h1>
    <br />
    <h5 class="text-blue">{{ getService.summary }}</h5>
    <br /><br />
    <p v-html="getService.details" class="text-blue"></p>
    <br />
    <button
      v-if="getProfile.roles.includes('admin')"
      class="btn btn-outline-success"
      @click.prevent="editService"
    >
      EDIT
    </button>
    <button
      v-if="getProfile.roles.includes('admin')"
      class="btn btn-outline-danger"
      @click.prevent="deleteService"
    >
      DELETE
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Service",
  computed: { ...mapGetters(["getProfile", "getService"]) },
  data() {
    return {
      error: "",
      editLink: "/services/edit",
    };
  },
  methods: {
    editService() {
      const service = {
        id: this.getService.id,
        name: this.getService.name,
        summary: this.getService.summary,
        details: this.getService.details,
      };
      this.$store.commit("serviceStatus", service);
      this.$router.push(this.editLink);
    },
    async deleteService() {
      const res = await this.$store.dispatch(
        "deleteService",
        this.getService.id
      );
      if (!res.isValid) {
        this.error = "An error has occured";
        return;
      }
    },
  },
};
</script>

<style scoped>
.btn {
  margin-right: 10px !important;
}
</style>
